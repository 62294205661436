import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { User, UsersFacade } from '@core/users';
import { map } from 'rxjs/operators';
import { Role } from '@core/users/enums/role.enum';

const ACCESS_DENIED_PAGE_URL = '/error?statusCode=403';

export function roleGuardFactory(role: Role | Role[]): CanActivateFn {
  return () => {
    const router = inject(Router);
    const usersFacade = inject(UsersFacade);

    return usersFacade.currentUser$
      .pipe(
        map((user: User | null) => {
          const accessDenied = router.parseUrl(ACCESS_DENIED_PAGE_URL)

          if (!user?.roles) {
            return accessDenied;
          }

          const hasRequiredRole = Array.isArray(role)
            ? role.some(role => user.roles.includes(role))
            : user.roles.includes(role);

          if (hasRequiredRole) {
            return true;
          }

          return accessDenied;
        })
      );
  };
}

export const anyRoleGuard = roleGuardFactory([Role.Viewer, Role.Admin, Role.SuperAdmin]);

export const superAdminRoleGuard: CanActivateFn = roleGuardFactory(Role.SuperAdmin);

export const adminRoleGuard: CanActivateFn = roleGuardFactory(Role.Admin);

export const viewerRoleGuard: CanActivateFn = roleGuardFactory(Role.Viewer);
