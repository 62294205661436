import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { adminRoleGuard, anyRoleGuard, superAdminRoleGuard } from '@core/users/guards/role-factory.guard';

const commonCanActivateGuards= [
  MsalGuard,
];

const routes: Routes = [
  {
    path: 'office-utilization',
    loadChildren: () => import('./pages/building/building.module').then(m => m.BuildingModule),
    canActivate: [...commonCanActivateGuards, anyRoleGuard]
  },
  {
    path: 'floor-utilization',
    loadChildren: () => import('./pages/floor/floor.module').then(m => m.FloorModule),
    canActivate: [...commonCanActivateGuards, anyRoleGuard]
  },
  {
    path: 'admin/manage-mappings',
    loadChildren: () => import('./pages/manage-mappings/manage-mappings.module').then(mod => mod.ManageMappingsModule),
    canActivate: [...commonCanActivateGuards, adminRoleGuard]
  },
  {
    path: 'admin/users',
    loadChildren: () => import('./pages/users-grid/users-grid.module').then(mod => mod.UsersGridModule),
    canActivate: [...commonCanActivateGuards, superAdminRoleGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate: [...commonCanActivateGuards, anyRoleGuard]
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
