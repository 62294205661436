import * as moment from 'moment';
import { dateTimeFormat } from '@shared/constants';

export const MS_IN_SEC = 1000;
export const MS_IN_MIN = MS_IN_SEC * 60;
export const MS_IN_HOUR = MS_IN_MIN * 60;
export const MS_IN_DAY = MS_IN_HOUR * 24;
export const MS_IN_WEEK = MS_IN_DAY * 7;

(function setupTimeThresholdsForHumanization() {
  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('mm', 60);
  moment.relativeTimeThreshold('hh', 24);
  moment.relativeTimeThreshold('dd', 2);
})();

export function formatToServerDateString(date: Date): string {
  return moment(date).utc().set({
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  }).format(dateTimeFormat);
}

export function getTimeFromNow(utcDateTimeString: string): string {
  const local = parseUtc(utcDateTimeString);
  const daysDiffFromNow = getTimeDifferenceFromNow(local, 'days');

  if (daysDiffFromNow > 2) {
    return formatDate(local, 'MM/DD/yyyy');
  }

  return moment(local).fromNow();
}

export function getTimeDifferenceFromNow(date: Date, unitOfTime: moment.unitOfTime.Diff) {
  const now = moment().local();

  return now.diff(moment(date), unitOfTime);
}

export function parseUtc(utcDateTimeString: string): Date {
  return moment.utc(utcDateTimeString).toDate();
}

export function formatDate(date: Date, format: string): string {
  return moment(date).format(format);
}

export function formatDateString(date: string, format: string): string {
  return formatUTCDate(date, format);
}

export function formatDateTime(dateTime: number, format: string): string {
  return formatUTCDate(dateTime, format);
}

export function formatUTCDate(utcInput: moment.MomentInput, format: string) {
  return moment.utc(utcInput).format(format);
}

export function formatDateRange(startDate: string, endDate: string): string {
  const s = moment.utc(startDate);
  const e = moment.utc(endDate);

  return `${s.format(`MM/DD, hh:mm A`)} - ${e.format(`MM/DD, hh:mm A`)}`;
}

export function setDateTime(dateString: string, setObject: moment.MomentSetObject = {}, format = dateTimeFormat): string {
  return moment.utc(dateString)
    .set(setObject)
    .format(format);
}

export function addDurationMsUTC(dateString: string, ms: number, format = dateTimeFormat): string {
  return moment.utc(dateString).add(ms).format(format);
}

export function getDatesDiff(startDate: string, endDate: string, unitOfTime: moment.unitOfTime.Diff): number {
  return moment.utc(endDate).diff(moment.utc(startDate), unitOfTime);
}

export function getDaysInMonth(date: string): number {
  return moment.utc(date).daysInMonth();
}

export function getStartOf(date: Date, startOf: moment.unitOfTime.StartOf): number {
  return moment(date).startOf(startOf).get('day');
}

export function getEndOf(date: Date, endOf: moment.unitOfTime.StartOf): number {
  return moment(date).endOf(endOf).get('day');
}

export function subtractDurationMsUTC(dateString: string, ms: number, format = dateTimeFormat): string {
  return moment.utc(dateString).subtract(ms).format(format);
}

type TimeKeys = 'hours' | 'minutes' | 'seconds';

export function checkTimeUTC(dateTimeString: string, time: Pick<moment.MomentSetObject, TimeKeys>): boolean {
  const date = moment.utc(dateTimeString);

  return Object.entries(time)
    .every(([timeKey, value]) => {
      return value === date.get(timeKey as TimeKeys);
    });
}

export class DateRange {
  constructor(
    public startDate: string,
    public endDate: string,
  ) {
  }

  static from(startDate: string, endDate: string, boundaries?: {
    start?: moment.MomentSetObject;
    end?: moment.MomentSetObject
  }): DateRange {
    if (!boundaries) {
      return new DateRange(startDate, endDate);
    }

    if (boundaries?.start) {
      startDate = moment.utc(startDate).set(boundaries.start).format(dateTimeFormat);
    }

    if (boundaries?.end) {
      endDate = moment.utc(endDate).set(boundaries.end).format(dateTimeFormat);
    }

    return new DateRange(startDate, endDate);
  }

  equals(dateRange: DateRange): boolean {
    return this.startDate === dateRange.startDate && this.endDate === this.endDate;
  }

  within(dateRange: DateRange): boolean {
    const thisStartDate = moment.utc(this.startDate);
    const thisEndDate = moment.utc(this.endDate);
    const outStartDate = moment.utc(dateRange.startDate);
    const outEndDate = moment.utc(dateRange.endDate);

    return !(thisStartDate.isBefore(outStartDate) || thisStartDate.isAfter(outEndDate)) &&
      !(thisEndDate.isBefore(outStartDate) || thisEndDate.isAfter(outEndDate));
  }

  clone(boundaries?: { start?: moment.MomentSetObject; end?: moment.MomentSetObject }): DateRange {
    return DateRange.from(this.startDate, this.endDate, boundaries);
  }

  toString(): string {
    return `${this.startDate} - ${this.endDate}`;
  }
}
